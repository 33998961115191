<template>
  <v-row class="inner-content">
    <v-col>

      <v-card class="pb-3">
        <v-card-title>
          Create campaigns
          <a class="ml-3" style="font-size: 0.8em;" href="https://docs.google.com/spreadsheets/d/1uMxSNNB6zPTYNFIG3gOT5OBmoxm4E1-lCbq7knG1wCA/edit#gid=1777761786" target="_blank"><v-icon>help</v-icon></a>
        </v-card-title>

        <v-card-text>

          <v-row>
            <v-col>
              <p>Create campaigns by selecting an account and then pasting the campaign details into the field below.</p>
            </v-col>
          </v-row>

          <v-form ref="form">

            <v-row>
              <v-col md="4">
                <BingAccountAutoComplete
                  v-model="selectedDestinationAccountID"
                  label="Destination account" 
                  @changed="destinationAccountChanged"
                ></BingAccountAutoComplete>
              </v-col>

              <v-col md="4">
                <BingAccountAutoComplete
                  v-model="selectedBaseAccountID"
                  label="Optional: base Bing account" 
                  :mandatory="false" 
                  @changed="baseAccountChanged"
                ></BingAccountAutoComplete>

                <BingCampaignsMultiAutoComplete 
                  v-model="selectedBaseCampaigns"
                  :accountID="selectedBaseAccountID"
                  label="Base Bing account campaigns" 
                  :mandatory="false"
                ></BingCampaignsMultiAutoComplete>

                <v-btn style="min-width: 165px;" class="mt-4" color="success" :disabled="!selectedDestinationAccountID || selectedBaseCampaigns.length == 0" @click="fillFromBingBase()">Fill from Bing base</v-btn>
                <span class="ml-2" style="color: red;" v-show="!selectedDestinationAccountID && selectedBaseCampaigns.length != 0">Select destination account</span>
              </v-col>

              <v-col md="4">
                <GoogleAccountAutoComplete
                  v-model="selectedBaseGoogleAccountID"
                  label="Optional: base Google account" 
                  :mandatory="false" 
                  @changed="baseGoogleAccountChanged"
                ></GoogleAccountAutoComplete>

                <GoogleCampaignsMultiAutoComplete 
                  v-model="selectedBaseGoogleCampaignIDs"
                  :accountID="selectedBaseGoogleAccountID"
                  label="Base Google account campaigns" 
                  :mandatory="false"
                ></GoogleCampaignsMultiAutoComplete>

                <v-btn style="min-width: 165px;" class="mt-4" color="success" :loading="fillingFromGoogle" :disabled="!selectedDestinationAccountID || selectedBaseGoogleCampaignIDs.length == 0" @click="fillFromGoogleBase()">Fill from Google base</v-btn>
                <span class="ml-2" style="color: red;" v-show="!selectedDestinationAccountID && selectedBaseGoogleCampaignIDs.length != 0">Select destination account</span>
              </v-col>
              
            </v-row>

            <v-row>
              <v-col md="12">
                <v-text-field style="font-size: 0.9em;" dense :placeholder="campaignsHeader" disabled></v-text-field>
                <v-textarea v-model="campaignsContent" clearable dense rows="6" hint="Paste campaigns here. Columns should be TAB separated, and values should be pipe (|) separated." style="background-color: lightyellow;"
                  required :rules="[v => !!v || 'Campaigns are required']" @change="campaignsContentChanged">
                </v-textarea>
              </v-col>
            </v-row>

            <v-row>
              <v-col md="12">
                <v-btn color="success" :loading="validating" @click="create(true)">Validate</v-btn>
                <v-fade-transition mode="out-in">
                  <v-btn color="green darken-1" text v-show="showValid">Valid</v-btn>
                </v-fade-transition>
              </v-col>
            </v-row>

            <v-row>
              <v-col md="12">
                <v-alert v-model="showErrorAlert" color="error" border="left" elevation="2" colored-border dismissible class="mt-4 ml-2 mr-2" icon="error" style="white-space: pre-line;">{{errorMsg}}</v-alert>
              </v-col>
            </v-row>
          </v-form>

          <v-row>
            <v-col md="12" :hidden="!validated">
              <v-simple-table class="mt-4">
                <thead>
                  <tr class="pb-0">
                    <th>Campaign</th>
                    <th>Budget amount</th>
                    <th>Shared budget name</th>
                    <th>Bidding strategy</th>
                    <th>BS value</th>
                    <th>BS max CPC</th>
                    <th>Portfolio BS name</th>
                    <th>DSA Website</th>
                    <th>DSA Language</th>
                    <th>Target geos</th>
                    <th>Target langs</th>
                    <th>Negative sites</th>
                    <th>S.MatchType</th>
                    <th>S.AgeGroup</th>
                    <th>S.Gender</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in parsedCampaigns" :key="item.name">
                    <td>{{item.name}}</td>
                    <td>{{item.budget_amount}}</td>
                    <td>{{item.shared_budget_name}}</td>
                    <td>{{item.bidding_strategy_type}}</td>
                    <td>{{item.bidding_strategy_value_str}}</td>
                    <td>{{item.bidding_strategy_max_cpc_str}}</td>
                    <td>{{item.portfolio_bs_name}}</td>
                    <td>{{item.dsa_website}}</td>
                    <td>{{item.dsa_language}}</td>
                    <td>{{item.target_geos_str}}</td>
                    <td>{{item.target_langs_str}}</td>
                    <td>{{item.negative_sites_str}}</td>
                    <td>{{item.match_type_segments_str}}</td>
                    <td>{{item.age_group_segments_str}}</td>
                    <td>{{item.gender_segments_str}}</td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-col>
          </v-row>

          <v-row>
            <v-col md="12" :hidden="!validated">
              <v-btn class="mt-0" color="primary" :loading="creating" @click="create(false)">Create</v-btn>
              <v-fade-transition mode="out-in">
                <v-btn color="primary darken-1" text v-show="showCreated">Created</v-btn>
              </v-fade-transition>
            </v-col>
          </v-row>

        </v-card-text>
      </v-card>

    </v-col>
  </v-row>

</template>

<script>
import ccMixin from '@/mixins/contentCreator'
import formsMixin from '@/mixins/forms'

export default {
  name: 'BingCampaigns',

  data () {
    return {
      campaignsContent: '',
      parsedCampaigns: [],
      campaignsHeader: 'Campaign   Budget amount   Shared budget name   Bidding strategy   BS value   BS max CPC   Portfolio BS name   DSA website   DSA language   Target geos   Target langs   Negative sites   S.MatchType   S.AgeGroup   S.Gender',
      selectedDestinationAccountID: 0,
      selectedBaseAccountID: 0,
      selectedBaseCampaigns: [],
      selectedBaseGoogleAccountID: 0,
      selectedBaseGoogleCampaignIDs: [],
      fillingFromGoogle: false,
      validating: false,
      creating: false,
      lsSelectedDestinationAccount: 'contentCreator_selectedDestinationAccountBing',
      lsSelectedBaseAccount: 'contentCreator_selectedBaseAccountBing',
      lsSelectedBaseGoogleAccount: 'contentCreator_selectedBaseAccount',
      lsContent: 'contentCreator_campaignsContentBing',
      validated: false,
      showValid: false,
      showCreated: false,
      showErrorAlert: false,
      errorMsg: '',
    }
  },

  mixins: [ccMixin, formsMixin],

  computed: {
    baseEndpoint () {
      return '/a/bing/accounts/' + this.selectedBaseAccountID + '/content-creator/campaigns?xper_page=2000'
    },

    baseGoogleEndpoint () {
      return '/a/bing/accounts/' + this.selectedDestinationAccountID + '/content-creator/google-campaigns?google_account_id=' + this.selectedBaseGoogleAccountID + '&xper_page=2000'
    },

    destEndpoint () {
      return '/a/bing/accounts/' + this.selectedDestinationAccountID + '/content-creator/campaigns'
    },
  },

  components: {
    BingAccountAutoComplete: () => import('@/components/autocompletes/BingAccountAutoComplete.vue'),
    BingCampaignsMultiAutoComplete: () => import('@/components/autocompletes/BingCampaignsMultiAutoComplete.vue'),
    GoogleAccountAutoComplete: () => import('@/components/autocompletes/GoogleAccountAutoComplete.vue'),
    GoogleCampaignsMultiAutoComplete: () => import('@/components/autocompletes/GoogleCampaignsMultiAutoComplete.vue'),
  },

  methods: {
    baseAccountChanged: function () {
      if (!this.selectedBaseAccountID) {
        this.selectedBaseAccountID = 0
      }

      this.selectedBaseCampaigns = []

      localStorage.setItem(this.lsSelectedBaseAccount, JSON.stringify(this.selectedBaseAccountID))
    },

    baseGoogleAccountChanged: function () {
      if (!this.selectedBaseGoogleAccountID) {
        this.selectedBaseGoogleAccountID = 0
      }

      this.selectedBaseGoogleCampaignIDs = []

      localStorage.setItem(this.lsSelectedBaseGoogleAccount, JSON.stringify(this.selectedBaseGoogleAccountID))
    },

    campaignsContentChanged: function () {
      this.validated = false
      this.showValid = false
      this.showErrorAlert = false

      // save to local storage
      localStorage.setItem(this.lsContent, JSON.stringify(this.campaignsContent))
    },

    create (validateOnly = true) {
      this.showErrorAlert = false
      if (!this.$refs.form.validate()) {
        return false
      }

      var action = 'creating'
      var showVar = 'showCreated'
      if (validateOnly) {
        this.validated = false
        action = 'validating'
        showVar = 'showValid'
      }

      this[action] = true

      var splitLines = this.campaignsContent.split('\n')
      var obj = {
        'lines': splitLines,
        'validate_only': validateOnly
      }
      var body = JSON.stringify(obj)

      this.$http.post(this.destEndpoint, body).then(resp => {
        if (resp.data.data.error_msgs) {
          this.errorMsg = this.forms_getValidationDisplay(resp.data.data.error_msgs, 'error(s)')
          this.showErrorAlert = true
          this.parsedCampaigns = []
        } else {
          if (resp.data.data.parsed_campaigns) {
            this.parsedCampaigns = resp.data.data.parsed_campaigns
            this.validated = true
            this[showVar] = true
            this.timer = setTimeout(() => { this[showVar] = false }, this.cc_buttonDisplayMS)
          } else {
            this.parsedCampaigns = []
          }
        }
      })
      .catch(errResp => {
        this.validated = false
        this.$store.commit('system/setAPIError', { method: 'POST', url: this.destEndpoint, description: errResp.response.data.err_description, ext_msg: errResp.response.data.external_message })
      })
      .finally(() => this[action] = false)
    },

    destinationAccountChanged: function () {
      localStorage.setItem(this.lsSelectedDestinationAccount, JSON.stringify(this.selectedDestinationAccountID))
    },

    fillFromBingBase: function () {
      if (this.selectedBaseAccountID == 0 || this.selectedBaseCampaigns.length == 0) {
        return
      }

      this.campaignsContent = ''
      this.validated = false
      this.showErrorAlert = false

      // fetch DB data for selected campaigns
      var myURL = this.baseEndpoint + '&campaign_id=' + this.selectedBaseCampaigns.map(v => v.campaign_id).join('|')

      this.$http.get(myURL).then(resp => {
        if (resp.data.data) {

          // put the DB data into tab-separated format
          var content = ''
          resp.data.data.forEach(v => {
            content += v.campaign_name
            content += '\t' + v.budget_amount
            content += '\t' + v.budget_name
            content += '\t' + v.bs_type
            content += '\t' + v.bs_value
            content += '\t' + v.bs_max_cpc
            content += '\t' + v.portfolio_bs_name
            content += '\t' + v.dsa_website
            content += '\t' + v.dsa_language
            content += '\t' + v.target_geos
            content += '\t' + v.target_langs
            content += '\t' + v.negative_sites
            content += '\t' + v.match_type_segments
            content += '\t' + v.age_group_segments
            content += '\t' + v.gender_segments
            content += '\n'
          })
          this.campaignsContent = content
        }
      })
      .catch(errResp => {
        this.$store.commit('system/setAPIError', { method: 'GET', url: myURL, description: errResp.response.data.err_description, ext_msg: errResp.response.data.external_message })
      })
    },

    fillFromGoogleBase: function () {
      if (this.selectedBaseGoogleAccountID == 0 || this.selectedBaseGoogleCampaignIDs.length == 0) {
        return
      }

      this.campaignsContent = ''
      this.validated = false
      this.showErrorAlert = false
      this.fillingFromGoogle = true

      // fetch DB data for selected campaigns
      var myURL = this.baseGoogleEndpoint + '&campaign_id=' + this.selectedBaseGoogleCampaignIDs.join('|')

      this.$http.get(myURL).then(resp => {
        if (resp.data.data) {

          // put the DB data into tab-separated format
          var content = ''
          resp.data.data.forEach(v => {
            content += v.campaign_name
            content += '\t' + v.budget_amount
            content += '\t' + v.budget_name
            content += '\t' + v.bs_type
            content += '\t' + v.bs_value
            content += '\t' + v.bs_max_cpc
            content += '\t' + v.portfolio_bs_name
            content += '\t' + v.dsa_website
            content += '\t' + v.dsa_language
            content += '\t' + v.target_geos
            content += '\t' + v.target_langs
            content += '\t' + v.negative_sites
            content += '\t' + v.match_type_segments
            content += '\t' + v.age_group_segments
            content += '\t' + v.gender_segments
            content += '\n'
          })
          if (content) {
            this.campaignsContent = content
          } else {
            this.campaignsContent = 'The selected campaign(s) already exists in the destination Bing account.'
          }
        }
      })
      .catch(errResp => {
        this.$store.commit('system/setAPIError', { method: 'GET', url: myURL, description: errResp.response.data.err_description, ext_msg: errResp.response.data.external_message })
      })
      .finally(() => this.fillingFromGoogle = false)
    },
  },

  created: function () {
    // if present, use local accounts
    if (localStorage.getItem(this.lsSelectedDestinationAccount)) {
      this.selectedDestinationAccountID = JSON.parse(localStorage.getItem(this.lsSelectedDestinationAccount))
      this.destinationAccountChanged()
    }
    if (localStorage.getItem(this.lsSelectedBaseAccount)) {
      this.selectedBaseAccountID = JSON.parse(localStorage.getItem(this.lsSelectedBaseAccount))
      this.baseAccountChanged()
    }
    if (localStorage.getItem(this.lsSelectedBaseGoogleAccount)) {
      this.selectedBaseGoogleAccountID = JSON.parse(localStorage.getItem(this.lsSelectedBaseGoogleAccount))
      this.baseGoogleAccountChanged()
    }

    // if present, use content
    if (localStorage.getItem(this.lsContent)) {
      this.campaignsContent = JSON.parse(localStorage.getItem(this.lsContent))
    }
  },

}
</script>

<style scoped>
</style>